import { Button, Grid } from '@mui/material';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import SiteHeader from '../../components/SiteHeader';
import UnitDetailSubHeader from './UnitDetailSubHeader';
import AuthContext from '../../context/AuthContext';
import { Park, StorageUnit } from '../../model';
import { checkPermissionsForResource } from '@ivy/auth';

interface Props {
  park: Park;
  storageUnit: StorageUnit;
}

const UnitDetailHeader: React.FC<Props> = ({ park, storageUnit }) => {
  const navigate = useNavigate();
  const auth = React.useContext(AuthContext);

  if (park === undefined || park === null || storageUnit === undefined || storageUnit === null) {
    return null;
  }

  const deleteButton = {
    text: 'Delete Unit',
    url: `/park/${park.id}/unit/${storageUnit.id}/delete`,
  };

  const breadcrumbs = [
    {
      title: 'Parks',
      path: '/parks',
    },
    {
      title: park.name,
      path: '/park/' + park?.id,
    },
  ];

  return (
    <>
      <SiteHeader title={storageUnit.visualId} breadcrumps={breadcrumbs}>
        {checkPermissionsForResource(auth.user?.token!, 'storageUnit', 'delete') && (
          <Grid>
            <Grid item xs={12}>
              <Button
                variant="outlined"
                size="medium"
                fullWidth
                color="error"
                onClick={() => navigate(deleteButton.url)}
              >
                {deleteButton.text}
              </Button>
            </Grid>
          </Grid>
        )}
      </SiteHeader>
      <UnitDetailSubHeader {...{ park }} />
    </>
  );
};

export default UnitDetailHeader;
