import Box from '@mui/material/Box';
import * as React from 'react';
import IvyAdmins from './IvyAdmins';
import AdministrateCompanies from './AdministrateCompanies';
import { checkPermissionsForResource } from '@ivy/auth';
import AuthContext from '../../context/AuthContext';

const Admin: React.FC = () => {
  const auth = React.useContext(AuthContext);
  return (
    <>
      <AdministrateCompanies />
      <Box m={4} />
      {checkPermissionsForResource(auth.user?.token!, 'user', 'read') && <IvyAdmins />}
    </>
  );
};

export default Admin;
