import { TableRow, TableCell, Box, Typography, Popover } from '@mui/material';
import { Files, HouseSimple, Check } from 'phosphor-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import LoadingIcon from '../../components/LoadingIcon';
import { TenantEntry } from '../../context/TenantsContext';
import { Company } from '../../model';
import styled from '@emotion/styled';
import { colors } from '../../theming/colors';
import AuthContext from '../../context/AuthContext';
import { checkPermissionsForResource } from '@ivy/auth';

const Cell = styled.div`
  display: flex;
  align-items: center;
`;

export const TenantsTableRow: React.FC<{
  tenant: TenantEntry;
  company: Company | null | undefined;
  loadingParks: boolean;
}> = ({ tenant, company, loadingParks }) => {
  const navigate = useNavigate();
  const auth = React.useContext(AuthContext);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const openTenant = (uid: string) => {
    navigate(`/tenants/${uid}`);
  };

  return (
    <TableRow
      className={'e2e-shows-customer-details'}
      hover={checkPermissionsForResource(auth.user?.token!, 'user', 'update')}
      onClick={() => {
        if (checkPermissionsForResource(auth.user?.token!, 'user', 'update')) {
          openTenant(tenant.id);
        }
      }}
      key={tenant.id}
      sx={{
        cursor: checkPermissionsForResource(auth.user?.token!, 'user', 'update') ? 'pointer' : 'default',
        '&:last-child td, &:last-child th': { border: 0 },
      }}
    >
      <TableCell>
        <b>{`${tenant.name}`}</b>
      </TableCell>
      <TableCell>{`${tenant.email}`}</TableCell>

      <TableCell>
        <Cell>
          <Files size={24} />
          <Box m={0.5} />
          {tenant.rentals}
        </Cell>
      </TableCell>
      <TableCell>
        <Typography
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          {loadingParks ? (
            <LoadingIcon size={16} />
          ) : tenant.parks.length ? (
            tenant.parks.length > 1 ? (
              `${tenant.parks[0]}, ...`
            ) : (
              tenant.parks[0]
            )
          ) : (
            '-'
          )}
        </Typography>
        {tenant.parks.length > 1 ? (
          <Popover
            id="mouse-over-popover"
            sx={{
              pointerEvents: 'none',
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            {tenant.parks.join(', ')}
          </Popover>
        ) : null}
      </TableCell>
      <TableCell>
        <Cell>
          <HouseSimple size={24} />
          <Box m={0.5} />
          {tenant.units}
        </Cell>
      </TableCell>
      <TableCell>
        <Typography>{tenant.language}</Typography>
      </TableCell>
      <TableCell>
        {new Date(tenant.created).toLocaleString('de-DE', {
          year: '2-digit',
          month: '2-digit',
          day: '2-digit',
        })}
      </TableCell>
      <TableCell>
        {new Date(tenant.updated).toLocaleString('de-DE', {
          year: '2-digit',
          month: '2-digit',
          day: '2-digit',
        })}
      </TableCell>
      {company?.flags?.betaFeatures && (
        <TableCell>
          <Cell>
            <Check size={24} color={colors.signalGreen} />
            <Box m={0.5} />
            {tenant.trust}
          </Cell>
        </TableCell>
      )}
    </TableRow>
  );
};
