import { checkPermissionsForResource } from '@ivy/auth';
import { Box, Button, Grid, Tooltip } from '@mui/material';
import { Scroll, TrashSimple } from 'phosphor-react';
import * as React from 'react';
import { Control } from 'react-hook-form';
import ReactHookFormInput, { ReactHookFormInputType } from '../../../components/form/ReactHookFormInput';
import AuthContext from '../../../context/AuthContext';
import { ParkListPark } from '../../../gateway/park/get';
import { CompanyStorageUnitListPayloadStorageUnit } from '../../../gateway/storageUnit/get';
import { TenantFormData, TenantKeyFormData } from './TenantFormTypes';

interface ITenantFormKeysRowProps {
  formControl: Control<TenantFormData>;
  storageUnits: CompanyStorageUnitListPayloadStorageUnit[];
  storageUnitKey: TenantKeyFormData;
  index: number;
  parks: ParkListPark[];
  remove: (keyIndex: number) => void;
}

const TenantFormKeysRow: React.FunctionComponent<ITenantFormKeysRowProps> = ({
  formControl,
  storageUnitKey,
  index,
  parks,
  storageUnits,
  remove,
}) => {
  const auth = React.useContext(AuthContext);

  // The inputs are disabled if the key is already assigned to a customer
  const disabled = storageUnitKey.customerId.length > 0;

  const park = parks.find(park => park.id === storageUnitKey.parkId);

  const filteredStorageUnitOptions = React.useMemo(
    () =>
      storageUnits
        ?.filter(storageUnit => storageUnit.parkId === park?.id)
        .map(storageUnit => ({ name: storageUnit.visualId, value: storageUnit.id })),
    [park?.id, storageUnits],
  );

  const noUnitsForPark = !!park && filteredStorageUnitOptions.length === 0;

  return (
    <Grid
      item
      className="cy-tenant-unit-key"
      xs={12}
      sx={{
        display: !storageUnitKey.removed ? 'flex' : 'none',
        flexDirection: 'row',
        alignItems: 'flex-end',
      }}
    >
      <Grid container spacing={1}>
        <Grid item xs={12} md={4}>
          <ReactHookFormInput
            control={formControl}
            type={ReactHookFormInputType.select}
            name={`keys.${index}.parkId`}
            label="Park"
            rules={{ required: !disabled }}
            disabled={disabled}
            options={parks.map(park => ({ name: park.name, value: park.id }))}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <ReactHookFormInput
            control={formControl}
            type={ReactHookFormInputType.select}
            name={`keys.${index}.storageUnitId`}
            label={noUnitsForPark ? 'No units for selected park' : 'Unit'}
            rules={{ required: !disabled }}
            disabled={disabled}
            options={filteredStorageUnitOptions}
          />
        </Grid>
        <Grid item xs={10} md={3} alignSelf={'self-end'}>
          {storageUnitKey.isRentalPrimaryKey && (
            <Tooltip title="This tenant is the rental owner for this unit">
              <Scroll size={24} />
            </Tooltip>
          )}
        </Grid>
        {checkPermissionsForResource(auth.user?.token!, 'key', 'update') && (
          <Grid item xs={2} md={2} justifyContent="flex-end" alignSelf={'self-end'}>
            <Box display="flex" justifyContent="flex-end">
              <Tooltip title="Revoke key from tenant">
                <Button
                  variant="outlined"
                  color="secondary"
                  size="small"
                  onClick={() => {
                    if (storageUnitKey.isRentalPrimaryKey) {
                      window.confirm(
                        'This key is belongs to the owner of the rental for this unit. Are you sure you want to remove it?',
                      ) && remove(index);
                    } else {
                      remove(index);
                    }
                  }}
                >
                  <TrashSimple size={24} />
                </Button>
              </Tooltip>
            </Box>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default TenantFormKeysRow;
