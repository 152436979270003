import { checkPermissionsForResource } from '@ivy/auth';
import { KeyStatus } from '@ivy/proto/dist/storage_units/v2/key';
import { ToastMessageTypes, useToaster } from '@ivy/toaster';
import GroupsIcon from '@mui/icons-material/Groups';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import WarningIcon from '@mui/icons-material/Warning';
import { Autocomplete, Box, Button, TextField, Tooltip, Typography } from '@mui/material';
import React, { useContext } from 'react';
import AuthContext from '../../../context/AuthContext';
import RentalManagementContext, { RentalEntry } from '../../../context/RentalManagementContext';
import { createKeyForRental } from '../../../gateway/storageUnitKeys/storageUnitKeysGateway';
import { TenantListItem } from '../../../gateway/tenantGateway';
import { nameEmailDivider } from '../../../util/util';
import UnitRentalManagementRentalEntryKey from './UnitRentalManagementRentalEntryKey';

const UnitRentalManagementRentalEntry: React.FC<{
  rental: RentalEntry;
  tenants?: TenantListItem[];
}> = ({ rental, tenants }) => {
  const isActiveOrUpcoming = new Date(rental.starts) > new Date() || rental.isActive;
  const [keysOpen, setKeysOpen] = React.useState(rental.isActive);
  const [addKeyArea, setAddKeyArea] = React.useState<{ rentalId: string } | undefined>();
  const { setEditRentalModal, setEndRentalModal, setSharedAccessModal } = useContext(RentalManagementContext);
  const addingKeysEnabled = rental.keys.length < 2 || rental.shareAccessActive;
  const auth = React.useContext(AuthContext);

  const canUpdateRental = checkPermissionsForResource(auth.user?.token!, 'rental', 'update');

  return (
    <Box
      className="e2e-rental-entry"
      sx={{
        backgroundColor: 'white',
        border: rental.isActive ? 1 : 0,
        borderRadius: 2,
        boxSizing: 'border-box',
        boxShadow: rental.isActive ? '0px 5px 8px rgba(0, 0, 0, 0.2)' : '0px 5px 8px rgba(0, 0, 0, 0.1)',
        mb: 2,
      }}
    >
      <Box
        sx={{
          borderBottom: keysOpen ? '1px solid lightgrey' : '',
          display: 'flex',
          justifyContent: 'space-between',
          p: 2,
        }}
      >
        <Box>
          <Typography
            className="e2e-rental-entry-date"
            variant="h6"
            sx={{
              mb: 1,
              fontWeight: 700,
              ':hover': canUpdateRental ? { cursor: 'pointer', textDecoration: 'underline' } : undefined,
            }}
            onClick={canUpdateRental ? () => setEditRentalModal({ rentalId: rental.id }) : undefined}
          >
            {new Date(rental.starts).toLocaleDateString('en-GB', {
              timeZone: 'UTC',
            })}{' '}
            -{' '}
            {rental.expires
              ? new Date(rental.expires).toLocaleDateString('en-GB', {
                  timeZone: 'UTC',
                })
              : 'No end date'}
            <Tooltip
              title={`Access Logs ${rental.accessLogsActive ? 'Enabled' : 'Disabled'}`}
              sx={{
                display: 'inline-block',
                ml: 2,
                mt: -0.5,
                verticalAlign: 'middle',
              }}
            >
              <ManageSearchIcon color={rental.accessLogsActive ? 'primary' : 'disabled'} />
            </Tooltip>
            <Tooltip
              title={`Shared Access ${rental.shareAccessActive ? 'Enabled' : 'Disabled'}`}
              sx={{
                display: 'inline-block',
                ml: 2,
                mt: -0.5,
                verticalAlign: 'middle',
              }}
            >
              <GroupsIcon color={rental.shareAccessActive ? 'primary' : 'disabled'} />
            </Tooltip>
          </Typography>
          <Typography>
            Status:{' '}
            <Box component="span" fontWeight={rental.isActive ? 700 : 400} color={rental.isActive ? 'green' : 'black'}>
              {new Date(rental.starts) > new Date() ? 'Upcoming' : rental.isActive ? 'Active' : 'Ended'}
            </Box>
          </Typography>
          <Typography>Primary Tenant: {rental.tenant}</Typography>
          {isActiveOrUpcoming &&
          !rental.keys.find(key => key.tenantId === rental.tenantId && key.status === KeyStatus.KEY_STATUS_ACTIVE) ? (
            <>
              <WarningIcon
                sx={{
                  display: 'inline-block',
                  marginRight: 1.5,
                  verticalAlign: 'middle',
                  color: 'orange',
                }}
              />
              <Typography sx={{ display: 'inline-block', verticalAlign: 'middle', fontWeight: 700, color: 'orange' }}>
                The rental owner does not have an active key.
              </Typography>
            </>
          ) : (
            ''
          )}
        </Box>
        <Box
          sx={{
            display: {
              xs: 'flex',
              lg: 'block',
            },
            flexDirection: 'column',
          }}
        >
          <Button
            className="e2e-rental-entry-view-keys"
            color="inherit"
            variant="outlined"
            onClick={() => {
              setKeysOpen(!keysOpen);
            }}
            sx={{
              width: { xs: '100%', lg: 'auto' },
              ml: 0,
              mb: {
                xs: 1,
                lg: 0,
              },
            }}
          >
            {keysOpen ? 'Hide Keys' : 'View Keys'}
          </Button>
          {checkPermissionsForResource(auth.user?.token!, 'rental', 'update') && isActiveOrUpcoming ? (
            <>
              <Button
                color="primary"
                variant="outlined"
                onClick={() => setEditRentalModal({ rentalId: rental.id })}
                sx={{
                  width: { xs: '100%', lg: 'auto' },
                  ml: {
                    xs: 0,
                    lg: 1,
                  },
                  mb: {
                    xs: 1,
                    lg: 0,
                  },
                }}
              >
                Edit Rental
              </Button>
              <Button
                color="secondary"
                variant="outlined"
                onClick={() => setEndRentalModal({ rentalId: rental.id })}
                sx={{
                  width: { xs: '100%', lg: 'auto' },
                  ml: {
                    xs: 0,
                    lg: 1,
                  },
                  mb: 0,
                }}
              >
                End Rental
              </Button>
            </>
          ) : (
            ''
          )}
        </Box>
      </Box>
      {keysOpen ? (
        <Box className="e2e-rental-entry-keys" sx={{ p: 2 }}>
          {rental.keys.length === 0 ? <Typography>This rental does not have any keys.</Typography> : ''}
          {rental.keys
            .sort((a, b) => {
              if (a.tenantId === rental.tenantId) return -1;
              if (b.tenantId === rental.tenantId) return 1;

              if (a.status === KeyStatus.KEY_STATUS_ACTIVE && b.status !== KeyStatus.KEY_STATUS_ACTIVE) return -1;
              if (b.status === KeyStatus.KEY_STATUS_ACTIVE && a.status !== KeyStatus.KEY_STATUS_ACTIVE) return 1;

              return 0;
            })
            .map((key, index) => (
              <UnitRentalManagementRentalEntryKey
                key={index}
                rentalKey={key}
                rental={rental}
                storageUnitId={rental.storageUnitId}
              />
            ))}
          {addKeyArea ? (
            <UnitRentalManagementRentalEntryAddKey
              setAddKeyArea={setAddKeyArea}
              rentalId={addKeyArea.rentalId}
              tenants={tenants}
            />
          ) : (
            ''
          )}
          {isActiveOrUpcoming ? (
            <Box
              sx={{
                display: 'flex',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  ml: 'auto',
                }}
              >
                {rental.keys.length === 2 && !rental.shareAccessActive ? (
                  <Button
                    className="e2e-rental-entry-enable-shared-access"
                    color="primary"
                    variant="outlined"
                    onClick={() => {
                      setSharedAccessModal({ rental });
                    }}
                    sx={{
                      mr: 2,
                    }}
                  >
                    Enable Shared Access
                  </Button>
                ) : (
                  ''
                )}

                {checkPermissionsForResource(auth.user?.token!, 'key', 'create') && (
                  <Tooltip title={!addingKeysEnabled && 'Enable shared access to add more keys'}>
                    <Box>
                      <Button
                        className="e2e-rental-entry-add-key"
                        color="primary"
                        variant="outlined"
                        disabled={!addingKeysEnabled}
                        onClick={() => {
                          setAddKeyArea({ rentalId: rental.id });
                        }}
                      >
                        Add Key
                      </Button>
                    </Box>
                  </Tooltip>
                )}
              </Box>
            </Box>
          ) : (
            ''
          )}
        </Box>
      ) : (
        ''
      )}
    </Box>
  );
};

const UnitRentalManagementRentalEntryAddKey: React.FC<{
  rentalId: string;
  setAddKeyArea: React.Dispatch<React.SetStateAction<{ rentalId: string } | undefined>>;
  tenants?: TenantListItem[];
}> = ({ rentalId, setAddKeyArea, tenants }) => {
  const { toaster } = useToaster();
  const [selectedTenant, setSelectedTenant] = React.useState<string | undefined>();
  const { rentals } = useContext(RentalManagementContext);
  const assignKey = async () => {
    if (!selectedTenant) {
      toaster('Error Occurred', 'Please select a tenant.', ToastMessageTypes.ERROR);
      return;
    }
    try {
      await createKeyForRental(rentalId, selectedTenant, KeyStatus.KEY_STATUS_ACTIVE);
      rentals.refresh();
      setAddKeyArea(undefined);
      toaster('Action Successful', 'Key has been added successfully', ToastMessageTypes.SUCCESS);
    } catch (e) {
      console.log(e);
      toaster('Error Occurred', e.response.data.message, ToastMessageTypes.ERROR);
    }
    return false;
  };
  return (
    <Box
      className="e2e-rental-entry-add-key-area"
      sx={{
        backgroundColor: 'rgb(239, 239, 240)',
        borderRadius: 2,
        display: 'flex',
        mb: 2,
        p: 2,
      }}
    >
      <Box>
        <Autocomplete
          disablePortal
          options={
            tenants?.map(tenant => ({
              label: `${tenant.firstName} ${tenant.lastName}${nameEmailDivider}${tenant.email}`,
              id: tenant.id,
            })) || []
          }
          renderOption={(props, option) => {
            const [label, email] = option.label.split(nameEmailDivider);
            return (
              <li {...props} key={option.id}>
                <span style={{ textAlign: 'left', wordBreak: 'break-word' }}>{label + ' '}</span>
                <span style={{ flexGrow: 1 }}></span>
                <span style={{ opacity: 0.5, textAlign: 'right', wordBreak: 'break-word' }}>{email}</span>
              </li>
            );
          }}
          sx={{ backgroundColor: 'white', width: 600 }}
          renderInput={params => <TextField {...params} label="Tenant" />}
          onChange={(event, value) => {
            if (value) setSelectedTenant(value.id);
          }}
          isOptionEqualToValue={(option, value) => option.id === value.id}
        />
      </Box>
      <Box
        sx={{
          alignSelf: 'center',
          ml: 'auto',
        }}
      >
        <Button
          color="success"
          variant="outlined"
          sx={{
            backgroundColor: 'white',
            borderColor: 'lightgrey',
            ml: 2,
          }}
          onClick={() => assignKey()}
        >
          Assign Key To Tenant
        </Button>
        <Button
          color="inherit"
          variant="outlined"
          onClick={() => setAddKeyArea(undefined)}
          sx={{
            backgroundColor: 'white',
            borderColor: 'lightgrey',
            ml: 2,
          }}
        >
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

export default UnitRentalManagementRentalEntry;
