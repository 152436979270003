import { KeyStatus } from '@ivy/proto/dist/storage_units/v2/key';
import { Box, Typography, Button, Tooltip } from '@mui/material';
import React, { useContext } from 'react';
import KeyIcon from '@mui/icons-material/Key';
import KeyOffIcon from '@mui/icons-material/KeyOff';
import RentalManagementContext, { KeyAndTenant, RentalEntry } from '../../../context/RentalManagementContext';
import { reactivateKey } from '../../../gateway/storageUnitKeys/storageUnitKeysGateway';
import { ToastMessageTypes, useToaster } from '@ivy/toaster';
import { Scroll } from 'phosphor-react';
import { checkPermissionsForResource } from '@ivy/auth';
import AuthContext from '../../../context/AuthContext';

const UnitRentalManagementRentalEntryKey: React.FC<{
  rentalKey: KeyAndTenant;
  rental: RentalEntry;
  storageUnitId: string;
}> = ({ rentalKey: key, rental, storageUnitId }) => {
  const { setRevokeKeyModal } = useContext(RentalManagementContext);
  const { toaster } = useToaster();
  const { rentals } = useContext(RentalManagementContext);
  const auth = React.useContext(AuthContext);

  const handleReactivateKey = async () => {
    try {
      await reactivateKey(storageUnitId, key.id);
      rentals.refresh();
      toaster('Action Successful', 'Key has been reactivated', ToastMessageTypes.SUCCESS);
    } catch (e) {
      toaster('Error occurred reactivating key', e.response.data.message, ToastMessageTypes.ERROR);
    }
    return false;
  };
  return (
    <Box
      className="e2e-rental-key-entry"
      sx={{
        backgroundColor: key.status === KeyStatus.KEY_STATUS_ACTIVE ? 'rgb(239, 239, 240)' : 'rgb(241, 130, 141)',
        borderRadius: 2,
        display: 'flex',
        mb: 2,
        p: 2,
      }}
    >
      <Box>
        <Typography>
          {key.tenant?.firstName} {key.tenant?.lastName}
        </Typography>

        {key.status === KeyStatus.KEY_STATUS_ACTIVE ? (
          <Box
            sx={{
              alignContent: 'center',
              display: 'flex',
            }}
          >
            {/* If the rental is not active, and the key is unrevoked, display "Inactive" */}
            <Typography>{rental.isActive ? 'Active' : 'Inactive'}</Typography>{' '}
            {rental.isActive ? (
              <KeyIcon
                color="success"
                sx={{
                  ml: 0.5,
                }}
              />
            ) : (
              <Tooltip title="The key is not revoked, but the rental is not active.">
                <KeyOffIcon
                  color="success"
                  sx={{
                    ml: 0.5,
                  }}
                />
              </Tooltip>
            )}
          </Box>
        ) : (
          <Box
            sx={{
              alignContent: 'center',
              display: 'flex',
            }}
          >
            <Typography>Revoked</Typography>{' '}
            <KeyOffIcon
              color="error"
              sx={{
                ml: 0.5,
              }}
            />
          </Box>
        )}
        {key.tenantId === rental.tenantId ? (
          <Typography>
            Rental owner{' '}
            <Scroll
              size={20}
              style={{
                display: 'inline-block',
                verticalAlign: 'middle',
              }}
            />
          </Typography>
        ) : (
          ''
        )}
      </Box>
      {checkPermissionsForResource(auth.user?.token!, 'key', 'update') &&
      (new Date(rental.starts) > new Date() || rental.isActive) ? (
        <Box
          sx={{
            alignSelf: 'center',
            ml: 'auto',
          }}
        >
          {key.status === KeyStatus.KEY_STATUS_ACTIVE ? (
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => setRevokeKeyModal({ key, rental })}
              sx={{
                backgroundColor: 'white',
                borderColor: 'secondary',
                ml: 2,
              }}
            >
              Revoke
            </Button>
          ) : (
            <Button
              color="inherit"
              variant="outlined"
              onClick={() => handleReactivateKey()}
              sx={{
                backgroundColor: 'white',
                borderColor: 'lightgrey',
                ml: 2,
              }}
            >
              Reactivate
            </Button>
          )}
        </Box>
      ) : (
        ''
      )}
    </Box>
  );
};

export default UnitRentalManagementRentalEntryKey;
