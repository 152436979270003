import { checkPermissionsForResource } from '@ivy/auth';
import { Box, Button, MenuItem, TextField, Typography } from '@mui/material';
import { ArrowUp } from 'phosphor-react';
import * as React from 'react';
import { useContext } from 'react';
import RentalManagementContext, { RentalEntry } from '../../../context/RentalManagementContext';
import { useTenants } from '../../../gateway/tenantGateway';
import { StorageUnit } from '../../../model';
import { Order, sortByEntry } from '../../../util/util';
import UnitRentalEndDialog from '../UnitRentalEndDialog';
import UnitRentalManagementEditDialog from './UnitRentalManagementEditDialog';
import UnitRentalManagementKeyRevokeDialog from './UnitRentalManagementKeyRevokeDialog';
import UnitRentalManagementRentalEntry from './UnitRentalManagementRentalEntry';
import UnitRentalManagementSharedAccessDialog from './UnitRentalManagementSharedAccessDialog';
import AuthContext from '../../../context/AuthContext';

interface Props {
  storageUnit: StorageUnit;
}

const sortOptions = [
  { name: 'Start Date', value: 'starts' },
  { name: 'End Date', value: 'expires' },
  { name: 'Status', value: 'isActive' },
  { name: 'Tenant', value: 'tenant' },
];

const UnitRentalManagementSection: React.FC<Props> = ({ storageUnit }) => {
  const tenants = useTenants();
  const [order, setOrder] = React.useState<Order>('desc');
  const [orderBy, setOrderBy] = React.useState<keyof RentalEntry>('starts');
  const [sortedRentals, setSortedRentals] = React.useState<RentalEntry[]>([]);
  const auth = React.useContext(AuthContext);
  const {
    endRentalModal,
    editRentalModal,
    revokeKeyModal,
    setEditRentalModal,
    setEndRentalModal,
    setRevokeKeyModal,
    sharedAccessModal,
    setSharedAccessModal,
    rentals,
  } = useContext(RentalManagementContext);

  const parseRentalRows = React.useCallback(() => {
    if (rentals.data) {
      const parsedRentals: RentalEntry[] = rentals.data.map(data => {
        const rental = data.rental;
        const tenant = tenants?.find(x => x.id === rental?.tenantId);
        return {
          ...rental,
          tenant: tenant ? `${tenant.firstName} ${tenant.lastName}` : '-',
          keys: data.keys.map(key => ({
            ...key,
            tenant: tenants?.find(x => x.id === key.tenantId),
          })),
        };
      });
      setSortedRentals(sortByEntry(parsedRentals, orderBy, order));
    }
  }, [rentals.data, tenants, orderBy, order]);

  React.useEffect(() => {
    parseRentalRows();
  }, [rentals.data, parseRentalRows]);

  return (
    <>
      {endRentalModal ? (
        <UnitRentalEndDialog
          closeDialog={() => {
            rentals.refresh();
            setEndRentalModal(undefined);
          }}
          rentalId={endRentalModal.rentalId}
        />
      ) : (
        ''
      )}
      {editRentalModal ? (
        <UnitRentalManagementEditDialog
          closeDialog={() => {
            rentals.refresh();
            setEditRentalModal(undefined);
          }}
          rentalId={editRentalModal.rentalId}
          storageUnitId={storageUnit.id}
          tenants={tenants}
        />
      ) : (
        ''
      )}
      {revokeKeyModal ? (
        <UnitRentalManagementKeyRevokeDialog
          closeDialog={() => {
            rentals.refresh();
            setRevokeKeyModal(undefined);
          }}
          keyAndTenant={revokeKeyModal.key}
          rental={revokeKeyModal.rental}
          storageUnitId={storageUnit.id}
        />
      ) : (
        ''
      )}
      {sharedAccessModal ? (
        <UnitRentalManagementSharedAccessDialog
          closeDialog={() => {
            rentals.refresh();
            setSharedAccessModal(undefined);
          }}
          rental={sharedAccessModal.rental}
        />
      ) : (
        ''
      )}
      <Box sx={{ py: 2 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            mb: 3,
          }}
        >
          <Typography variant="h5" sx={{ alignSelf: 'flex-end' }}>
            Rentals
            {checkPermissionsForResource(auth.user?.token!, 'rental', 'create') && (
              <Button
                className="e2e-rentals-create-button"
                variant="outlined"
                color="primary"
                sx={{
                  ml: 2,
                }}
                onClick={() =>
                  setEditRentalModal({
                    rentalId: undefined,
                  })
                }
              >
                Create Rental
              </Button>
            )}
          </Typography>
          <Box>
            <TextField
              className="e2e-rentals-sort-by"
              label={'Sort By'}
              select={true}
              variant="standard"
              SelectProps={{
                displayEmpty: true,
                defaultValue: 'starts',
              }}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={e => setOrderBy(e.target.value as keyof RentalEntry)}
            >
              {sortOptions?.map((option, index) => (
                <MenuItem key={index} value={option.value}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
            <Box
              className="e2e-rentals-sort-direction"
              sx={{
                p: 0.5,
                ':hover': { backgroundColor: 'lightgray' },
                borderRadius: 1,
                display: 'inline-block',
                verticalAlign: 'bottom',
              }}
              onClick={() => {
                setOrder(order === 'asc' ? 'desc' : 'asc');
              }}
            >
              <ArrowUp style={{ rotate: order === 'desc' ? '180deg' : '' }} />
            </Box>
          </Box>
        </Box>
        {sortedRentals.map((rental, index) => (
          <UnitRentalManagementRentalEntry key={index} rental={rental} tenants={tenants} />
        ))}
      </Box>
    </>
  );
};

export default UnitRentalManagementSection;
