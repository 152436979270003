import { Button, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';

import * as React from 'react';
import SiteHeader from '../../components/SiteHeader';
import AuthContext from '../../context/AuthContext';
import Operator from './Operator';
import TableLoading from '../../components/TableLoading';
import { logout } from '../../auth/auth';
import { checkPermissionsForResource } from '@ivy/auth';

const Profile: React.FC = () => {
  const auth = React.useContext(AuthContext);

  return (
    <>
      <SiteHeader title={'Profile'}>
        <Button variant="outlined" size="medium" onClick={() => logout()}>
          Logout
        </Button>
      </SiteHeader>

      {auth.user === undefined && <TableLoading />}
      {auth.user !== undefined && auth.user !== null && (
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>uid</TableCell>
              <TableCell>email</TableCell>
              <TableCell>role</TableCell>
              <TableCell>token</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow>
              <TableCell>{auth.user.id}</TableCell>
              <TableCell>{auth.user.email}</TableCell>
              <TableCell>{auth.user.role}</TableCell>
              <TableCell className={'e2e-shows-auth-token'}>
                <span data-token-for-e2e={auth.user.token}>
                  {auth.user.token.slice(0, 6) + '…' + auth.user.token.slice(-6)}
                </span>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}

      {checkPermissionsForResource(auth.user?.token!, 'user', 'create') && <Operator />}
    </>
  );
};

export default Profile;
