import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import * as React from 'react';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';

import { SmartMeterError, SmartMeterPowerState } from '@ivy/proto/dist/devices/v2/device';
import { DeviceTelemetryAggregate, DeviceTelemetryType } from '@ivy/proto/dist/devices/v2/telemetry';
import { ToastMessageTypes, useToaster } from '@ivy/toaster';
import { LoadingButton } from '@mui/lab';
import Box from '@mui/material/Box';
import { Warning } from 'phosphor-react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getDeviceTelemetry, updateSmartMeterPowerStatus, useDevice } from '../../../../../gateway/deviceGateway';
import { colors } from '../../../../../theming/colors';
import { TelemetryData } from '../TelemetryChart';
import { decodeTelemetry } from '../charts';
import EnergyConsumption from './EnergyConsumption';
import LoadingIcon from '../../../../../components/LoadingIcon';
import AuthContext from '../../../../../context/AuthContext';
import UnassignDeviceButton from '../../../../../components/UnassignDeviceButton';
import { checkPermissionsForResource } from '@ivy/auth';
import DeviceMacAddress from '../DeviceMacAdress';

export const mapErrorMessage = (err: string) => {
  if (err) {
    const error = err.split('_').at(-1);
    return `Device error: ${error?.toLowerCase() || 'unrecognized'}`;
  }
};

const PowerUsageDialog: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const user = React.useContext(AuthContext).user!;
  const id = params.deviceId;

  const device = useDevice(id!);
  const [powerState, setPowerState] = React.useState<SmartMeterPowerState>(
    SmartMeterPowerState.SMART_METER_POWER_STATE_ON,
  );

  const now = new Date();
  const lastMonth = new Date();
  lastMonth.setDate(now.getDate() - 30);

  const [startDate, setStartDate] = React.useState<Dayjs>(dayjs(lastMonth));
  const [endDate, setEndDate] = React.useState<Dayjs>(dayjs(now));
  const [energyConsumptionDeltas, setEnergyConsumptionDeltas] = React.useState<TelemetryData[]>([]); // Energy consumption increases per time increment, in kWh

  const [loading, setLoading] = React.useState(true);
  const { toaster } = useToaster();

  const isPowerButtonDisabledValue = React.useMemo(() => {
    const isOvertemp = device?.smartMeterData?.errors?.value.includes(SmartMeterError.SMART_METER_ERROR_OVERTEMP);
    return loading || isOvertemp;
  }, [loading, device?.smartMeterData?.errors?.value]);

  const onClose = () => {
    //remove device/id
    const parts = location.pathname.split('/');
    const prevPath = parts.slice(0, parts.length - 2).join('/');
    navigate(prevPath, {
      replace: true,
    });
  };

  const toggleSwitch = async () => {
    setLoading(true);
    try {
      if (id === undefined) throw new Error('Device ID could not be found');
      const newState =
        powerState === SmartMeterPowerState.SMART_METER_POWER_STATE_ON
          ? SmartMeterPowerState.SMART_METER_POWER_STATE_OFF
          : SmartMeterPowerState.SMART_METER_POWER_STATE_ON;
      await updateSmartMeterPowerStatus(id, newState);
      setPowerState(newState);
      toaster('Action Successful', 'Power supply was toggled', ToastMessageTypes.SUCCESS);
    } catch (e) {
      toaster('Action Failed', 'Power supply could not be toggled', ToastMessageTypes.ERROR);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (device) {
      setLoading(false);
    }
  }, [device]);

  React.useEffect(() => {
    if (device?.smartMeterData?.powerState?.value) {
      setPowerState(device.smartMeterData.powerState.value);
    }
  }, [device?.smartMeterData?.powerState?.value]);

  const fetchTelemetry = React.useCallback(async () => {
    if (id === undefined) return;
    const req = {
      types: [DeviceTelemetryType.DEVICE_TELEMETRY_TYPE_TOTAL_ENERGY],
      startTime: new Date(startDate.subtract(1, 'day').startOf('day').toDate()).toISOString(), // -1 day to allow getting the starting energy consumption
      endTime: new Date(endDate?.endOf('day').toDate()).toISOString(),
      aggregate: DeviceTelemetryAggregate.DEVICE_TELEMETRY_AGGREGATE_MAX,
      interval: 1000 * 60 * 60 * 24, // 1 day
    };
    const telemetry = await getDeviceTelemetry(id, req);

    if (telemetry.length === 0) {
      setEnergyConsumptionDeltas([]);
    } else {
      const energyTelemetry = telemetry[0].telemetry;

      // Store the rest of the telemetry to show in the graph
      let previousTelemetry: TelemetryData;
      setEnergyConsumptionDeltas(
        energyTelemetry.reduce((deltas, telemetry, i) => {
          // If startDate is the first day telemetry was ever recorded with this device, first delta is the first telemetry value
          // If not, then start deltas from second telemetry value and use the first as the reference point
          if (i === 0) {
            previousTelemetry = decodeTelemetry(telemetry);
            const isPreviousDay = dayjs(previousTelemetry.timestamp).startOf('day').isBefore(startDate.startOf('day'));
            if (isPreviousDay) {
              return [];
            } else {
              return [
                {
                  ...previousTelemetry,
                  value: previousTelemetry.value / 1_000,
                },
              ];
            }
          }

          const decodedTelemetry = decodeTelemetry(telemetry);

          const res = deltas.concat({
            ...decodedTelemetry,
            value: (decodedTelemetry.value - previousTelemetry.value) / 1_000, // Calculate delta, convert from Wh to kWh
          });

          previousTelemetry = decodedTelemetry;

          return res;
        }, [] as TelemetryData[]),
      );
    }
  }, [startDate, endDate, id]);

  const onStartDateChange = React.useCallback(value => {
    setStartDate(value);
  }, []);

  const onEndDateChange = React.useCallback(async value => {
    setEndDate(value);
  }, []);

  React.useEffect(() => {
    fetchTelemetry();
  }, [startDate, endDate, fetchTelemetry]);

  return (
    <Dialog open={true} fullWidth maxWidth="sm">
      <DialogContent>
        {device ? (
          <>
            <Typography variant="h2">Energy usage</Typography>
            <DeviceMacAddress />
            {device.smartMeterData?.errors?.value.length ? (
              <Box display="flex" bgcolor={colors.signalAlert} borderRadius={1} marginY={1} padding={1}>
                <Warning color={colors.white} size={24} />
                <Typography
                  variant="body1"
                  marginLeft={1}
                  style={{ color: colors.white, fontWeight: 'bold' }}
                  alignSelf="center"
                >
                  [{dayjs(device.smartMeterData.errors.timestamp).format('DD/MM/YY')}]{' '}
                  {device.smartMeterData.errors.value.map(mapErrorMessage).join(', ')}
                </Typography>
              </Box>
            ) : (
              <></>
            )}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Box display={'flex'} justifyContent={'space-between'} mt={3}>
                <DatePicker
                  disableFuture
                  label="Start date"
                  value={startDate}
                  format="DD/MM/YYYY"
                  onChange={onStartDateChange}
                  sx={{ marginRight: 2, width: '100%' }}
                  slotProps={{ textField: { size: 'small' } }}
                />
                <DatePicker
                  label="End date"
                  disableFuture
                  minDate={startDate}
                  maxDate={dayjs(new Date())}
                  format="DD/MM/YYYY"
                  value={endDate}
                  onChange={onEndDateChange}
                  sx={{ width: '100%' }}
                  slotProps={{ textField: { size: 'small' } }}
                />
              </Box>
            </LocalizationProvider>
            <Box m={3} />
            {/* Energy consumption data view */}
            <EnergyConsumption
              deltas={energyConsumptionDeltas}
              startDate={startDate.toDate()}
              endDate={endDate.toDate()}
            />
            <Box m={4} />

            <Typography>Critical actions</Typography>

            <div style={{ display: 'flex', alignItems: 'baseline' }}>
              <Typography variant="body2" style={{ color: colors.grey06, flex: 1 }}>
                {powerState === SmartMeterPowerState.SMART_METER_POWER_STATE_ON
                  ? 'Deactivate power supply for this unit completely'
                  : 'Active power supply for this unit'}
              </Typography>

              <LoadingButton
                loading={loading}
                disabled={isPowerButtonDisabledValue}
                size="small"
                variant="outlined"
                color="secondary"
                onClick={() => toggleSwitch()}
              >
                {powerState === SmartMeterPowerState.SMART_METER_POWER_STATE_ON ? ' Turn off power' : ' Turn on power'}
              </LoadingButton>
            </div>
          </>
        ) : (
          <Box
            sx={{
              paddingTop: '2em',
              textAlign: 'center',
            }}
          >
            <LoadingIcon />
          </Box>
        )}
      </DialogContent>
      <DialogActions style={{ padding: 24 }}>
        {checkPermissionsForResource(user.token!, 'device', 'update') && (
          <UnassignDeviceButton
            deviceId={params.deviceId!}
            storageUnitId={device?.storageUnitId!}
            setParentLoadingState={setLoading}
            onComplete={onClose}
          />
        )}
        <Button variant="outlined" size="medium" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PowerUsageDialog;
