import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { X } from 'phosphor-react';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useStorageUnitKeysFor } from '../../gateway/storageUnitKeys/storageUnitKeysGateway';
import { ToastMessageTypes, useToaster } from '@ivy/toaster';
import { FormEvent, useState } from 'react';
import { endRental } from '../../gateway/storageUnit/rentals';
import LoadingIcon from '../../components/LoadingIcon';

const UnitRentalEndDialog: React.FC<{ closeDialog: () => void; rentalId: string }> = ({ closeDialog, rentalId }) => {
  const params = useParams();
  const storageUnitKeys = useStorageUnitKeysFor(params.storageUnitId!);
  const { toaster } = useToaster();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    try {
      await endRental(params.storageUnitId!, rentalId);
      toaster('Action Successful', 'Rental was ended.', ToastMessageTypes.SUCCESS);
      closeDialog();
    } catch (e) {
      setLoading(false);
      toaster('Error Occurred', 'Unable to end rental.', ToastMessageTypes.ERROR);
    }
    return false;
  };

  const onCancel = () => closeDialog();

  const activeKeys = storageUnitKeys?.filter(key => key.status && key.rentalId === rentalId);

  return (
    <>
      <Dialog open={true}>
        <form onSubmit={onSubmit}>
          <DialogTitle variant="h2">
            End Rental
            <IconButton
              onClick={onCancel}
              sx={{
                position: 'absolute',
                right: 12,
                top: 14,
              }}
            >
              {<X />}
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <Grid container spacing={4}>
              <Grid item xs={1}>
                <WarningAmberIcon color="error" fontSize="large" />
              </Grid>
              <Grid item xs={11}>
                <Typography sx={{ fontWeight: 700 }}>
                  This will set the end date to today, and revoke all associated keys.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {storageUnitKeys && activeKeys.length ? (
                  <Typography color="error" sx={{ fontWeight: 700 }}>
                    This rental has {activeKeys.length} active key{activeKeys.length > 1 ? 's' : ''}.
                  </Typography>
                ) : (
                  ''
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" size="medium" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              variant="contained"
              size="medium"
              color="error"
              type="submit"
              disabled={!storageUnitKeys || loading}
              style={{
                width: 150,
              }}
            >
              {loading ? <LoadingIcon /> : 'End Rental'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default UnitRentalEndDialog;
