import { Box, Button, CircularProgress, Link, TextField, Typography } from '@mui/material';
import { isAxiosError } from 'axios';
import { useFormik } from 'formik';
import * as React from 'react';
import { login } from '../../auth/auth';
import { useErrorMessage } from '../../util/Snackbars';
import InitialLayout from './InitialLayout';
import { config } from '../../config';

interface FormData {
  email: string;
  password: string;
}

const Login: React.FunctionComponent = () => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [ErrorMessage, showError] = useErrorMessage();

  const onSubmit = async (values: FormData) => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      await login(values.email, values.password);
      window.location.reload();
    } catch (e) {
      setLoading(false);
      if (isAxiosError(e) && (e.response?.data.message || e.response?.data)) {
        showError(e.response?.data.message ?? e.response?.data);
      } else {
        showError(e.message);
      }
    }
  };

  const formik = useFormik<FormData>({
    initialValues: { email: '', password: '' },
    onSubmit,
  });

  return (
    <>
      <InitialLayout>
        <form onSubmit={formik.handleSubmit}>
          <Box
            component="div"
            sx={{
              '& .MuiTextField-root': { mt: 1, mb: 1 },
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography variant="h1">Sign in to your account</Typography>
            <TextField
              id="email"
              label="Email address"
              variant="standard"
              type="email"
              autoComplete="username"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              required
            />
            <TextField
              id="password"
              label="Password"
              variant="standard"
              type="password"
              autoComplete="current-password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              required
            />
          </Box>
          <Box m={1} />
          <Link href={config.forgotPasswordUrl} target="_blank" rel="noopener noreferrer">
            <Typography variant="body2" fontWeight={800}>
              Forgot password?
            </Typography>
          </Link>

            <Button type="submit" variant="contained" sx={{ marginLeft: 'auto', minWidth: 120, marginTop: 1 }} disabled={loading}>
              {loading && <CircularProgress sx={{ color: 'white' }} size={24} />}
              {!loading && 'Anmelden'}
            </Button>
        </form>
      </InitialLayout>
      <ErrorMessage />
    </>
  );
};

export default Login;
