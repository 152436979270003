import { Grid, IconButton, TextField } from '@mui/material';
import _ from 'lodash';
import { TrashSimple } from 'phosphor-react';
import * as React from 'react';
import { useEffect } from 'react';
import { StorageUnit, Company } from '../../../model';
import { checkPermissionsForResource } from '@ivy/auth';
import AuthContext from '../../../context/AuthContext';

interface Props {
  company?: Company | null;
  hideUnitDeleteIcon?: boolean;
  index: number;
  remove: (index: number) => void;
  unit: StorageUnit;
  update: (index: number, unit: any) => void;
}

const StorageUnitForm: React.FC<Props> = ({ hideUnitDeleteIcon, index, remove, unit, update }) => {
  const [unitState, setUnitState] = React.useState(unit);
  const auth = React.useContext(AuthContext);
  const canUpdateUnit = checkPermissionsForResource(auth.user?.token!, 'storageUnit', 'update');

  const handleChange = (name: string, value: string) => {
    const newState = {
      ...unitState,
      [name]: value,
    };
    setUnitState(newState);
    update(index, newState);
  };

  useEffect(() => {
    setUnitState(unit);
  }, [unit]);

  return (
    <Grid
      item
      xs={12}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
      }}
    >
      <Grid container spacing={1}>
        <Grid item xs={3} lg={2}>
          <TextField
            label="Visual ID"
            variant="standard"
            type="text"
            value={unitState.visualId}
            onChange={e => handleChange('visualId', e.target.value)}
            fullWidth
            required
            disabled={!canUpdateUnit}
          />
        </Grid>
        <Grid item xs={12} lg={10}>
          <TextField
            label="Description"
            variant="standard"
            value={unitState.description}
            onChange={e => handleChange('description', e.target.value)}
            fullWidth
            multiline
            maxRows={1}
            type="text"
            disabled={!canUpdateUnit}
          />
        </Grid>
      </Grid>
      {!hideUnitDeleteIcon && (
        <IconButton onClick={() => remove(index)}>
          <TrashSimple size={24} />
        </IconButton>
      )}
    </Grid>
  );
};

export default StorageUnitForm;
