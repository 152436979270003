import { Button, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import * as React from 'react';
import { useCallback, useEffect } from 'react';
import SiteHeader from '../../components/SiteHeader';
import { getOperators, useOperators, mapOperators } from '../../gateway/companyGateway';
import AddOperatorForm from './AddOperator';
import { TrashSimple } from 'phosphor-react';
import DeleteOperatorDialog from './DeleteOperatorDialog';
import { Operator } from '../../model';
import AuthContext from '../../context/AuthContext';
import { checkPermissionsForResource } from '@ivy/auth';

const OpeartorView: React.FC = () => {
  const [formOpen, setFormOpen] = React.useState(false);
  const [operators, setOperators] = React.useState(useOperators());

  const [deleteOperatorModal, setDeleteOperatorModal] = React.useState<{ operator?: Operator } | undefined>();
  const auth = React.useContext(AuthContext);

  const fetchOperators = useCallback(() => {
    getOperators().then(data => {
      setOperators(mapOperators(data));
    });
  }, []);

  useEffect(() => {
    if (formOpen === false) {
      fetchOperators();
    }
  }, [fetchOperators, formOpen]);

  return (
    <>
      {deleteOperatorModal ? (
        <DeleteOperatorDialog
          closeDialog={() => {
            fetchOperators();
            setDeleteOperatorModal(undefined);
          }}
          operator={deleteOperatorModal.operator!}
        />
      ) : (
        ''
      )}
      {checkPermissionsForResource(auth.user?.token!, 'user', 'create') && (
        <AddOperatorForm open={formOpen} onClose={() => setFormOpen(false)} />
      )}

      <SiteHeader title="Operators">
        <Button variant="outlined" size="medium" onClick={() => setFormOpen(true)}>
          Add Operator
        </Button>
      </SiteHeader>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography>
                <b>Name</b>
              </Typography>
            </TableCell>
            <TableCell>
              <Typography>
                <b>Email</b>
              </Typography>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {operators?.map(operator => (
            <TableRow key={operator.id}>
              <TableCell>
                <Typography>{operator.name}</Typography>
              </TableCell>
              <TableCell>
                <Typography>{operator.email}</Typography>
              </TableCell>
              <TableCell align="right">
                {checkPermissionsForResource(auth.user?.token!, 'user', 'delete') && (
                  <Tooltip title="Delete this operator account">
                    <Button
                      className="e2e-delete-operator-button"
                      variant="outlined"
                      color="secondary"
                      size="small"
                      onClick={() => {
                        setDeleteOperatorModal({ operator });
                      }}
                      sx={{
                        mr: 2.5,
                      }}
                    >
                      <TrashSimple size={24} />
                    </Button>
                  </Tooltip>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export default OpeartorView;
