import { Button, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import * as React from 'react';
import SiteHeader from '../../components/SiteHeader';
import AddAdminForm from './AddAdminForm';
import { useAdmins, getAdmins, mapAdmins } from '../../gateway/adminGateway';
import TableLoading from '../../components/TableLoading';
import { useEffect } from 'react';
import { checkPermissionsForResource } from '@ivy/auth';
import AuthContext from '../../context/AuthContext';

const IvyAdmins: React.FC = () => {
  // FIXME users can just open /admin and see ivy admins
  const [formOpen, setFormOpen] = React.useState(false);
  const [admins, setAdmins] = React.useState(useAdmins());
  const auth = React.useContext(AuthContext);

  useEffect(() => {
    if (formOpen === false) {
      getAdmins().then(data => {
        setAdmins(mapAdmins(data));
      });
    }
  }, [formOpen]);

  return (
    <>
      {checkPermissionsForResource(auth.user?.token!, 'user', 'create') && (
        <AddAdminForm open={formOpen} onClose={() => setFormOpen(false)} />
      )}
      <SiteHeader title="Ivy Admin Users">
        {checkPermissionsForResource(auth.user?.token!, 'user', 'create') && (
          <Button variant="outlined" size="medium" onClick={() => setFormOpen(true)}>
            Add Ivy Admin
          </Button>
        )}
      </SiteHeader>

      {admins === undefined && <TableLoading />}
      {admins !== undefined && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography>
                  <b>Name</b>
                </Typography>
              </TableCell>
              <TableCell>
                <Typography>
                  <b>Email</b>
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {admins.map(admin => (
              <TableRow key={admin.id}>
                <TableCell>
                  <Typography>{admin.name}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{admin.email}</Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </>
  );
};

export default IvyAdmins;
