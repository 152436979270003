import { Region } from '@ivy/proto/dist/geo/v1/regions';

export const regionNames: Record<Region, string> = {
  [Region.REGION_AUSTRIA]: 'Austria',
  [Region.REGION_DENMARK]: 'Denmark',
  [Region.REGION_FRANCE]: 'France',
  [Region.REGION_GERMANY]: 'Germany',
  [Region.REGION_NORWAY]: 'Norway',
  [Region.UNRECOGNIZED]: 'Unknown',
};

export const regionCodes: Record<Region, string> = {
  [Region.REGION_AUSTRIA]: 'AT',
  [Region.REGION_DENMARK]: 'DK',
  [Region.REGION_FRANCE]: 'FR',
  [Region.REGION_GERMANY]: 'DE',
  [Region.REGION_NORWAY]: 'NO',
  [Region.UNRECOGNIZED]: 'Unknown',
};

export const allRegions = [
  Region.REGION_AUSTRIA,
  Region.REGION_DENMARK,
  Region.REGION_FRANCE,
  Region.REGION_GERMANY,
  Region.REGION_NORWAY,
];

export const DbToProtoRegions = (regions: string[]): Region[] => regions.map(region => region as Region);
export const ProtoToDbRegions = (regions: Region[]): string[] => regions.map(region => region as string);
