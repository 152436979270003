import { useEffect, useState } from 'react';
import { components } from '../../../gen/backoffice-service';
import { AddressLocation, Park } from '../../model';
import { get } from '../requestUtils';
import { getAuthToken } from '../../auth/auth';
import { Region } from '@ivy/proto/dist/geo/v1/regions';

type ParkListPayload = components['schemas']['ParkListPayload'];
export type ParkListPark = components['schemas']['ParkListPark'];
type GetParkPayload = components['schemas']['GetParkPayload'];

export const useParks = (): ParkListPark[] | undefined => {
  const [data, setData] = useState<ParkListPark[] | undefined>(undefined);
  useEffect(() => {
    getParks().then(data => {
      setData(data.parks);
    });
  }, []);
  return data;
};

export const getParks = async (includeDrafts?: boolean): Promise<ParkListPayload> => {
  const token = await getAuthToken();
  const response = await get(`/parks${includeDrafts ? '?includeDrafts=true' : ''}`, token);
  return response.data;
};

export const usePark = (parkId: String): Park | undefined => {
  const [data, setData] = useState<Park | undefined>(undefined);
  useEffect(() => {
    getPark(parkId).then(data => {
      setData({
        id: data.id,
        visualId: data.visualId,
        underConstruction: data.underConstruction,
        name: data.name,
        address: {
          line1: data.address.line1 || undefined,
          city: data.address.city,
          postalCode: data.address.postalCode,
          state: data.address.state || undefined,
          countryCode: data.address.countryCode,
          location: data.address.location as AddressLocation,
        },
        manager: data.manager
          ? {
              name: data.manager.firstName + ' ' + data.manager.lastName || '',
              contactChannels: data.manager.contactChannels,
            }
          : undefined,
        storageUnits: data.storageUnits.map(unit => {
          return {
            id: unit.id,
            visualId: unit.visualId,
            description: unit.description ?? '',
            parkId: unit.parkId,
            thingsboardId: unit.thingsboardId!,
            activeDeviceRatio: unit.activeDeviceRatio,
            rental: unit.rental,
          };
        }),
        unitIdCount: data.storageUnits.length,
        // FIXME
        rentals: data.rentals.map(rental => {
          return {
            id: rental.id || '',
            tenantId: rental.tenantId,
            parkId: 'FIXME',
            storageUnitId: rental.storageUnitId,
            starts: 'FIXME',
            ends: rental.expires,
            isActive: rental.isActive,
            shareAccessActive: false,
            accessLogsActive: false,
          };
        }),
        iotRegistry: data.iotRegistry || '',
        thingsboardId: data.thingsboardId,
        draft: data.draft,
        region: data.region as Region,
      });
    });
  }, [parkId]);
  return data;
};

export const getPark = async (parkId: String): Promise<GetParkPayload> => {
  const token = await getAuthToken();
  const response = await get(`/parks/${parkId}`, token);

  return response.data;
};
