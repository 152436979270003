const devConfig = {
  backendUrl: process.env.REACT_APP_CORE_HOST || 'https://backoffice-service.dev.ivy-solutions.de',
  betaUrl: process.env.REACT_APP_BACKOFFICE_BETA_URL || 'https://backoffice-beta.dev.ivy-solutions.de',
  geocodingApiKey: process.env.REACT_APP_GEOCODING_API_KEY || 'AIzaSyArUXUObo0PywfpHfIQU9pw0wqkbU5BxOQ',
  cypress: process.env.REACT_APP_CYPRESS || false,
  sentryEnabled: false,
  forgotPasswordUrl: process.env.REACT_APP_FORGOT_PASSWORD_URL || 'https://user-portal.dev.ivy-solutions.de/en/forgot-password',
};

const prodConfig = {
  backendUrl: 'https://backoffice-service.ivy-solutions.de',
  betaUrl: process.env.REACT_APP_BACKOFFICE_BETA_URL || 'https://backoffice-beta.ivy-solutions.de',
  geocodingApiKey: 'AIzaSyArUXUObo0PywfpHfIQU9pw0wqkbU5BxOQ',
  cypress: false,
  sentryEnabled: true,
  forgotPasswordUrl: process.env.REACT_APP_FORGOT_PASSWORD_URL || 'https://user-portal.ivy-solutions.de/en/forgot-password',
};

export const config = process.env.REACT_APP_PROJECT_ID === 'ivy-production-73547' ? prodConfig : devConfig;
