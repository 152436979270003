import { useParams } from 'react-router-dom';
import DataInfoCell from './DataInfoCell';
import * as React from 'react';
import { useDevice } from '../../../../gateway/deviceGateway';
import { Box } from '@mui/material';


const DeviceMacAddress: React.FC = () => {
    const params = useParams();
    const deviceId = params.deviceId;
    const device = useDevice(deviceId!);

    const getMacAddress = (name: string | undefined) => {
        if (!name) return '';
        let macAddress;
        if (name.includes('_')) {
            macAddress = name.split('_').at(-1)?.toLocaleUpperCase();
        } else {
            macAddress = name.split('-').at(-1)?.toLocaleUpperCase();
        }   
        return macAddress?.match(/.{1,2}/g)?.join(':');
    };

    return (
        <>
            {getMacAddress(device?.name) && (
                <Box display="flex" marginY={1} justifyContent="space-between">
            <DataInfoCell label="Device Mac Address" value={getMacAddress(device?.name)} unit={''} />
            </Box>
        )}
        </>
    );
}

export default DeviceMacAddress;