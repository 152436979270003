import axios from 'axios';
import { components } from '../../gen/backoffice-service';
import { config } from '../config';
import { withBearerAuthorization } from '../gateway/withBearerAuthorization';

type GetAuthTokenResponse = components['schemas']['GetAuthTokenResponse'];
type RefreshAuthTokenResponse = components['schemas']['RefreshAuthTokenResponse'];
type GetAuthUserResponse = {
  user: components['schemas']['AuthenticatedUser'];
};

export type AuthToken = {
  token: string;
  expiry: number;
};

/**
 * Refreshes the authentication token using the provided refresh token.
 * @param refreshToken - The refresh token used to obtain a new authentication token.
 * @returns A Promise that resolves to the new authentication token.
 * @throws Error if the refresh token is invalid.
 */
const refreshAuthToken = async (refreshToken: string): Promise<AuthToken> => {
  try {
    const newToken = await axios.post<RefreshAuthTokenResponse>(`${config.backendUrl}/auth/token/refresh`, {
      refreshToken,
    });
    return setAuthToken(newToken.data);
  } catch (e) {
    // if the refresh token is invalid, clear stored tokens from storage to prevent retry attempts
    sessionStorage.removeItem('authToken');
    localStorage.removeItem('refreshToken');
    throw new Error('Error refreshing auth token');
  }
};

/**
 * Sets the authentication token in session storage.
 *
 * @param authTokenResponse - The authentication token response object.
 * @param authTokenResponse.authToken - The authentication token.
 * @param authTokenResponse.expiresIn - The expiration time of the token in seconds.
 * @returns The authentication token object.
 */
const setAuthToken = (authTokenResponse: { authToken: string; expiresIn: number }): AuthToken => {
  const authToken = {
    token: authTokenResponse.authToken,
    expiry: new Date().getTime() + authTokenResponse.expiresIn * 1000,
  };
  sessionStorage.setItem('authToken', JSON.stringify(authToken));
  return authToken;
};

/**
 * Retrieves an authentication token.
 *
 * @returns A promise that resolves to the authentication token.
 * @throws An error if no refresh token is found.
 */
export const getAuthToken = async (forceRefresh?: boolean): Promise<string> => {
  let authToken: AuthToken | null;
  const refreshToken = localStorage.getItem('refreshToken');

  try {
    const storedToken = sessionStorage.getItem('authToken');
    authToken = storedToken ? (JSON.parse(storedToken) as AuthToken) : null;
  } catch (e) {
    // catch a SyntaxError if the stored token is not valid JSON
    throw new Error('Invalid auth token.');
  }

  // if there is no refresh token stored, any request which requires an auth token should
  // not be made and the user should be redirected to the login page
  if (!refreshToken) {
    if (window.location.pathname !== '/' && window.location.pathname !== '/passwordReset') window.location.href = '/';
    throw new Error('No refresh token found.');
  }

  // refresh token if it expires in less than 5 minutes, or has already expired
  if (forceRefresh === true || !authToken || authToken.expiry < new Date().getTime() + 5 * 60 * 1000) {
    return (await refreshAuthToken(refreshToken)).token;
  }

  return authToken.token;
};

/**
 * Retrieves the authenticated user's information.
 *
 * @param token - The authentication token.
 * @returns A Promise that resolves to the authenticated user response.
 */
export const getAuthUser = async (token: string): Promise<GetAuthUserResponse> =>
  (await axios.get<GetAuthUserResponse>(`${config.backendUrl}/auth/user`, withBearerAuthorization(token))).data;

/**
 * Logs in the user with the provided email and password.
 *
 * @param email - The user's email.
 * @param password - The user's password.
 */
export const login = async (email: string, password: string) => {
  const response = await axios.post<GetAuthTokenResponse>(
    `${config.backendUrl}/auth/login`,
    {
      email,
      password,
    },
    {
      withCredentials: true,
    },
  );
  setAuthToken(response.data);
  localStorage.setItem('refreshToken', response.data.refreshToken);
};

/**
 * Logs out the user by sending a logout request to the backend server,
 * removing the authentication tokens from the session and local storage,
 * and redirecting the user to the home page.
 * @throws {Error} If no user is logged in.
 */
export const logout = async () => {
  const token = await getAuthToken();
  if (!token) throw new Error('No user is logged in.');

  // invalidate any refresh tokens
  await axios.post(`${config.backendUrl}/auth/logout`, {}, withBearerAuthorization(token));

  // remove tokens from local / session storage
  sessionStorage.removeItem('authToken');
  localStorage.removeItem('refreshToken');

  window.location.href = '/';
};
