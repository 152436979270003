import { UserRole } from '@ivy/proto/dist/users/v2/auth';
import { decodeAuthToken } from '../auth-tokens';

export type ApplicationType = 'backoffice' | 'installation' | 'tenant';
export type UserApplicationPermissions = Record<UserRole, ApplicationType[]>;

export const applicationPermissionModel: Record<ApplicationType, UserRole[]> = {
  backoffice: [
    UserRole.USER_ROLE_SUPER_ADMIN,
    UserRole.USER_ROLE_ADMIN,
    UserRole.USER_ROLE_SERVICE_TEAM,
    UserRole.USER_ROLE_FACILITY_MANAGER,
  ],
  installation: [UserRole.USER_ROLE_SUPER_ADMIN, UserRole.USER_ROLE_ADMIN],
  tenant: [UserRole.USER_ROLE_SUPER_ADMIN, UserRole.USER_ROLE_FACILITY_MANAGER_EXTERNAL, UserRole.USER_ROLE_TENANT],
};

/**
 * Determines if a user has permission to access an application.
 */
export const authorizeRoleForApplication = (
  authToken: string,
  application: ApplicationType,
  deps?: { decodeAuthToken: typeof decodeAuthToken },
) => {
  const { role } = deps?.decodeAuthToken(authToken) ?? decodeAuthToken(authToken);

  const appPermissions = applicationPermissionModel[application];

  if (!appPermissions) {
    return false;
  }

  return appPermissions.includes(role);
};

/**
 * Determines if a user has permission to access an application.
 *
 * @param authToken - The auth token of the user.
 * @param application - The application the user is trying to access.
 */
export const checkPermissionsForApp = (authToken: string, application: ApplicationType) => {
  return authorizeRoleForApplication(authToken, application);
};
